@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap");
@font-face {
  font-family: 'Rodchenko';
  src: url("/static/fonts/Rodchenko.ttf");
}
html {
  font-family: 'Rodchenko', sans-serif;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  font-size: 16px;
}
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  html {
    font-size: 0.9375rem;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  html {
    font-size: calc((100vw / 64));
  }
}
@media only screen and (max-width: 450px) {
  html {
    font-size: calc((100vw / 28.125));
  }
}
body {
  margin: 0;
  position: relative;
  background: #253238;
}
main {
  padding-top: 6.6875rem;
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
*:focus {
  outline: none;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.container {
  margin: 0 auto;
  padding: 0 2.5rem;
  max-width: 76.25rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .container {
    padding: 0 1.875rem;
  }
}
@media only screen and (max-width: 720px) {
  .container {
    padding: 0 0.9375rem;
  }
}
.content {
  padding: 1.875rem 0 5.3125rem;
  position: relative;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .content {
    padding: 1.5rem 0 3rem;
  }
}
.content.overflow-hidden {
  overflow: hidden;
}
.background-layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 24.375rem;
  z-index: -1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.background-layout svg {
  height: 100%;
  width: 35rem;
}
@media only screen and (max-width: 1025px) {
  .background-layout {
    display: none;
  }
}
.background-layout.fullheight {
  display: block;
  height: 100%;
}
.background-layout.fullheight img {
  -webkit-transform: scale(2.5);
  -moz-transform: scale(2.5);
  -o-transform: scale(2.5);
  -ms-transform: scale(2.5);
  transform: scale(2.5);
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.swiper {
  height: 100%;
}
.swiper-slide {
  cursor: grab;
}
.swiper-wrapper {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-pagination-bullet {
  --swiper-pagination-bullet-horizontal-gap: 0.5625rem;
  --swiper-pagination-bullet-width: 0.75rem;
  --swiper-pagination-bullet-height: 0.75rem;
  --swiper-pagination-bullet-inactive-color: #90a4af;
  --swiper-pagination-color: #e6792d;
}
.mainpage-link {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: #758791;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .mainpage-link {
    display: none;
  }
}
.mainpage-link svg {
  margin-left: 0.1875rem;
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #253238;
}
.header .header-top {
  padding: 0.8125rem 0;
}
.header .header-top__list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.header .header-top__item {
  font-size: 1rem;
}
.header .header-bottom {
  padding: 0.625rem 0;
  background: #1a282e;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  -ms-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.header .header-bottom__row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media only screen and (max-width: 1025px) {
  .header .header-bottom__block.center {
    margin: 0 auto;
  }
}
.header .header-bottom.active #header-organizers span {
  color: #e6792d;
}
.header .header-bottom.active .header-nav__submenu {
  pointer-events: initial;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.header-logo {
  display: block;
  width: 10.9375rem;
  height: 2.8125rem;
}
.header-nav {
  margin: 0 auto;
  padding: 0 2.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-column-gap: 4.375rem;
  -moz-column-gap: 4.375rem;
  column-gap: 4.375rem;
}
@media only screen and (max-width: 1025px) {
  .header-nav {
    display: none;
  }
}
.header-nav__item {
  position: relative;
  cursor: pointer;
  font-size: 1.875rem;
}
.header-nav__item span {
  -webkit-transition: 0.3s color ease;
  -moz-transition: 0.3s color ease;
  -o-transition: 0.3s color ease;
  -ms-transition: 0.3s color ease;
  transition: 0.3s color ease;
}
@media only screen and (min-width: 1025px) {
  .header-nav__item:hover span {
    color: #e6792d;
  }
}
.header-nav__item.active {
  color: #e6792d;
}
.header-nav__submenu {
  padding-top: 1.75rem;
  position: absolute;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.header-nav__subitem {
  white-space: nowrap;
}
.header-nav__subitem svg {
  margin-right: 0.625rem;
  width: 0.8125rem;
  height: 0.8125rem;
}
.header-contacts {
  margin-right: 3.125rem;
  text-align: right;
}
@media only screen and (max-width: 1025px) {
  .header-contacts {
    margin: 0;
  }
}
.header-contacts__phone {
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
}
.header-contacts__phone span {
  color: #828d93;
}
@media only screen and (max-width: 720px) {
  .header-contacts__phone {
    margin: 0;
  }
}
.header-contacts__mail {
  color: #e6792d;
  font-size: 0.875rem;
}
@media only screen and (max-width: 720px) {
  .header-contacts__mail {
    display: none;
  }
}
.header-langpicker {
  position: relative;
  background: #e6792d;
  font-size: 1.25rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 1.5625rem;
  width: 1.5625rem;
  text-transform: uppercase;
}
.header-langpicker:hover .header-langpicker__values {
  max-height: 7.8125rem;
}
@media only screen and (max-width: 1025px) {
  .header-langpicker {
    display: none;
  }
}
.header-langpicker > span {
  padding: 0.125rem;
  display: block;
  color: #000;
}
.header-langpicker__values {
  position: absolute;
  top: 1.625rem;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 0.0625rem;
  overflow: hidden;
  -webkit-transition: max-height 0.3s ease;
  -moz-transition: max-height 0.3s ease;
  -o-transition: max-height 0.3s ease;
  -ms-transition: max-height 0.3s ease;
  transition: max-height 0.3s ease;
  max-height: 0;
  cursor: pointer;
}
.header-langpicker__values li {
  padding: 0.125rem;
  text-align: center;
  background-color: #828d93;
  width: 100%;
}
.header-langpicker__values li a {
  display: block;
}
.mobile {
  padding: 5rem 2.1875rem;
  position: fixed;
  background: #253238;
  width: 50%;
  right: 0;
  z-index: 10;
  -webkit-transition: -webkit-transform 0.3s ease;
  -moz-transition: -moz-transform 0.3s ease;
  -o-transition: -o-transform 0.3s ease;
  -ms-transition: -ms-transform 0.3s ease;
  transition: transform 0.3s ease;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  height: 100%;
}
.mobile:not(.open) {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
@media only screen and (min-width: 1025px) {
  .mobile {
    display: none;
  }
}
.mobile-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 4.375rem;
}
.mobile-menu__submenu {
  padding-top: 1rem;
  padding-left: 0.625rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 1.25rem;
  font-size: 1.25rem;
}
.mobile-menu__subitem {
  white-space: nowrap;
}
.mobile-menu__subitem svg {
  margin-right: 0.625rem;
  width: 0.8125rem;
  height: 0.8125rem;
}
.mobile-item {
  font-size: 2.25rem;
  color: #fff;
}
.mobile-item:first-letter {
  color: #e6792d;
}
.mobile-background {
  position: absolute;
  bottom: 0;
  height: 21.875rem;
  left: 0;
}
.footer {
  padding: 2rem 0 1.25rem;
  position: relative;
  background: #1a282e;
}
@media only screen and (max-width: 720px) {
  .footer {
    padding: 2.125rem 0 0.9375rem;
    text-align: center;
  }
}
.footer-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.footer-background svg {
  height: 100%;
  width: 30.75rem;
}
@media only screen and (max-width: 1025px) {
  .footer-background {
    display: none;
  }
}
.footer-logo {
  margin-bottom: 1.125rem;
  width: 10.9375rem;
  height: 2.8125rem;
}
.footer-row {
  margin-bottom: 5.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media only screen and (max-width: 720px) {
  .footer-row {
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    gap: 3.125rem;
  }
}
.footer-left {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .footer-left {
    font-size: 1rem;
  }
}
.footer-address {
  margin-bottom: 1.5rem;
}
.footer-phone {
  margin-bottom: 0.5625rem;
}
.footer-mail {
  color: #e6792d;
}
.footer-nav {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  gap: 4.375rem;
}
@media only screen and (max-width: 720px) {
  .footer-nav {
    gap: 2.5rem;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.footer-nav__item {
  font-size: 1.875rem;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #758791;
}
@media only screen and (min-width: 1025px) {
  .footer-nav__item:hover {
    color: #e6792d;
  }
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .footer-nav__item {
    font-size: 1.5rem;
  }
}
.footer-copyright {
  color: #758791;
  text-align: center;
}
.promotion-blocks {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 1.875rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .promotion-blocks {
    row-gap: 1rem;
  }
}
@media only screen and (max-width: 720px) {
  .promotion-blocks {
    row-gap: 0.625rem;
  }
}
.promotion-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: -0.9375rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .promotion-row {
    margin: -0.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .promotion-row {
    margin: -0.3125rem;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .promotion-row:first-child {
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.promotion-block {
  padding: 0.9375rem;
  width: calc((100% / 3));
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .promotion-block {
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .promotion-block {
    padding: 0.3125rem;
    width: 100% !important;
  }
}
.promotion-block--big {
  width: calc(100% / 3 * 2);
}
.promotion-additional {
  position: absolute;
  left: 2.4375rem;
  bottom: -2.875rem;
  text-transform: uppercase;
  -webkit-transition: bottom 0.6s ease;
  -moz-transition: bottom 0.6s ease;
  -o-transition: bottom 0.6s ease;
  -ms-transition: bottom 0.6s ease;
  transition: bottom 0.6s ease;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .promotion-additional {
    bottom: 3rem;
  }
}
@media only screen and (max-width: 720px) {
  .promotion-additional {
    left: 1.5rem;
    bottom: 3.125rem;
  }
}
.promotion-additional.rotated {
  left: initial;
  width: fit-content;
  -webkit-transform: translate(100%, 0) rotate(0deg);
  -moz-transform: translate(100%, 0) rotate(0deg);
  -o-transform: translate(100%, 0) rotate(0deg);
  -ms-transform: translate(100%, 0) rotate(0deg);
  transform: translate(100%, 0) rotate(0deg);
  -webkit-transition: -webkit-transform 0.6s ease;
  -moz-transition: -moz-transform 0.6s ease;
  -o-transition: -o-transform 0.6s ease;
  -ms-transition: -ms-transform 0.6s ease;
  transition: transform 0.6s ease;
}
@media only screen and (max-width: 1025px) {
  .promotion-additional.rotated {
    -webkit-transform: translate(5.625rem, -1.25rem) rotate(-45deg);
    -moz-transform: translate(5.625rem, -1.25rem) rotate(-45deg);
    -o-transform: translate(5.625rem, -1.25rem) rotate(-45deg);
    -ms-transform: translate(5.625rem, -1.25rem) rotate(-45deg);
    transform: translate(5.625rem, -1.25rem) rotate(-45deg);
  }
}
@media only screen and (max-width: 720px) {
  .promotion-additional.rotated {
    -webkit-transform: translate(12.5rem, -1.875rem) rotate(-45deg);
    -moz-transform: translate(12.5rem, -1.875rem) rotate(-45deg);
    -o-transform: translate(12.5rem, -1.875rem) rotate(-45deg);
    -ms-transform: translate(12.5rem, -1.875rem) rotate(-45deg);
    transform: translate(12.5rem, -1.875rem) rotate(-45deg);
  }
}
.promotion-title {
  font-size: 2.625rem;
  font-weight: bold;
  margin-bottom: 1.875rem;
}
.promotion-button {
  margin: 0 auto;
  padding: 0.75rem 0.9375rem;
  background: #e6792d;
  font-size: 1.375rem;
  width: fit-content;
  color: #000;
  -webkit-transition: 0.3s ease background-color;
  -moz-transition: 0.3s ease background-color;
  -o-transition: 0.3s ease background-color;
  -ms-transition: 0.3s ease background-color;
  transition: 0.3s ease background-color;
}
.promotion-button:hover {
  background-color: #fecd81;
}
.promotion-block__inner {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 22.5rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .promotion-block__inner {
    height: 18.75rem;
  }
}
@media only screen and (max-width: 720px) {
  .promotion-block__inner {
    height: calc(344 / 376 * 100%);
  }
}
@media only screen and (min-width: 1025px) {
  .promotion-block__inner:hover .promotion-additional:not(.rotated) {
    bottom: 3rem;
  }
  .promotion-block__inner:hover .promotion-additional.rotated {
    -webkit-transform: translate(9.375rem, -9.375rem) rotate(-45deg);
    -moz-transform: translate(9.375rem, -9.375rem) rotate(-45deg);
    -o-transform: translate(9.375rem, -9.375rem) rotate(-45deg);
    -ms-transform: translate(9.375rem, -9.375rem) rotate(-45deg);
    transform: translate(9.375rem, -9.375rem) rotate(-45deg);
  }
  .promotion-block__inner:hover img.elements {
    -webkit-transform: translate(1.25rem, 1.25rem) rotate(20deg);
    -moz-transform: translate(1.25rem, 1.25rem) rotate(20deg);
    -o-transform: translate(1.25rem, 1.25rem) rotate(20deg);
    -ms-transform: translate(1.25rem, 1.25rem) rotate(20deg);
    transform: translate(1.25rem, 1.25rem) rotate(20deg);
  }
  .promotion-block__inner:hover img.elements.left {
    -webkit-transform: translate(-10%, -10%) rotate(160deg);
    -moz-transform: translate(-10%, -10%) rotate(160deg);
    -o-transform: translate(-10%, -10%) rotate(160deg);
    -ms-transform: translate(-10%, -10%) rotate(160deg);
    transform: translate(-10%, -10%) rotate(160deg);
  }
  .promotion-block__inner:hover img.bg {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
  }
  .promotion-block__inner:hover .triangle img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .promotion-block__inner:hover .triangle-label {
    -webkit-transform: translate(-1.25rem, 50%);
    -moz-transform: translate(-1.25rem, 50%);
    -o-transform: translate(-1.25rem, 50%);
    -ms-transform: translate(-1.25rem, 50%);
    transform: translate(-1.25rem, 50%);
  }
}
.promotion-block__inner img.bg {
  -webkit-transition: opacity 0.6s ease;
  -moz-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  -ms-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
.promotion-block__inner img.elements {
  position: absolute;
  object-fit: contain;
  width: auto;
  height: auto;
  -webkit-transition: -webkit-transform 0.6s ease;
  -moz-transition: -moz-transform 0.6s ease;
  -o-transition: -o-transform 0.6s ease;
  -ms-transition: -ms-transform 0.6s ease;
  transition: transform 0.6s ease;
}
.promotion-block__inner img.elements.left {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  left: -3.75rem;
  top: -0.9375rem;
}
.promotion-block__inner img.elements.right {
  right: -3.75rem;
  bottom: -0.9375rem;
}
.promotion-block__inner .triangle {
  position: absolute;
  right: 0;
  bottom: 1.875rem;
  width: 5.4375rem;
  height: 10.625rem;
  overflow: hidden;
}
@media only screen and (max-width: 1025px) {
  .promotion-block__inner .triangle {
    font-size: 1.125rem;
    bottom: 50%;
    -webkit-transform: translateY(70%);
    -moz-transform: translateY(70%);
    -o-transform: translateY(70%);
    -ms-transform: translateY(70%);
    transform: translateY(70%);
  }
}
.promotion-block__inner .triangle img {
  -webkit-transition: -webkit-transform 0.6s ease;
  -moz-transition: -moz-transform 0.6s ease;
  -o-transition: -o-transform 0.6s ease;
  -ms-transition: -ms-transform 0.6s ease;
  transition: transform 0.6s ease;
}
@media only screen and (max-width: 1025px) {
  .promotion-block__inner .triangle img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.promotion-block__inner .triangle-label {
  position: absolute;
  right: 0;
  bottom: 50%;
  -webkit-transform: translate(110%, 50%);
  -moz-transform: translate(110%, 50%);
  -o-transform: translate(110%, 50%);
  -ms-transform: translate(110%, 50%);
  transform: translate(110%, 50%);
  -webkit-transition: -webkit-transform 0.6s ease;
  -moz-transition: -moz-transform 0.6s ease;
  -o-transition: -o-transform 0.6s ease;
  -ms-transition: -ms-transform 0.6s ease;
  transition: transform 0.6s ease;
}
@media only screen and (max-width: 1025px) {
  .promotion-block__inner .triangle-label {
    -webkit-transform: translate(-1.25rem, 50%);
    -moz-transform: translate(-1.25rem, 50%);
    -o-transform: translate(-1.25rem, 50%);
    -ms-transform: translate(-1.25rem, 50%);
    transform: translate(-1.25rem, 50%);
  }
}
.clients-container {
  padding: 5rem 0 4.625rem;
  position: relative;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .clients-container {
    padding: 3.75rem 0 3.25rem;
  }
}
@media only screen and (max-width: 720px) {
  .clients-container {
    padding: 2.5rem 0 3.25rem;
  }
}
.clients-container::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.3125rem;
  background-color: #90a4af;
}
@media only screen and (max-width: 720px) {
  .clients-container::before {
    height: 0.1875rem;
  }
}
.clients-row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.clients-title {
  padding-right: 1.625rem;
  font-size: 4rem;
  text-transform: uppercase;
  width: fit-content;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .clients-title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 720px) {
  .clients-title {
    font-size: 2.25rem;
  }
}
.clients-decorline {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.clients-decorline::before {
  content: '';
  position: absolute;
  top: 0.5rem;
  left: 0;
  right: 0;
  height: 0.3125rem;
  background-color: #90a4af;
}
@media only screen and (max-width: 720px) {
  .clients-decorline::before {
    height: 0.1875rem;
  }
}
.clients-slider .swiper-slide {
  width: auto;
  height: 12.5rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .clients-slider .swiper-slide {
    height: 10.5rem;
  }
}
.clients-slider {
  padding: 5rem 0 6.5625rem;
}
.clients-slider a {
  display: block;
  width: 100%;
  height: 100%;
}
.clients-slider .swiper-pagination {
  position: relative;
}
.burger-button {
  width: 1.75rem;
  height: 1.75rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
@media only screen and (min-width: 1025px) {
  .burger-button {
    display: none;
  }
}
.burger-button span {
  display: block;
  position: absolute;
  height: 0.125rem;
  width: 100%;
  background: #fff;
  opacity: 1;
  -ms-filter: none;
  filter: none;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.burger-button span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}
.burger-button span:nth-child(2) {
  top: 0.8125rem;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}
.burger-button span:nth-child(3) {
  top: 1.625rem;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}
.burger-button.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0.1875rem;
  left: 0.25rem;
}
.burger-button.open span:nth-child(2) {
  width: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.burger-button.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 1.4375rem;
  left: 0.25rem;
}
.info-block__title {
  font-size: 4rem;
  font-weight: bold;
  color: #758791;
  text-transform: uppercase;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .info-block__title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 720px) {
  .info-block__title {
    font-size: 2.25rem;
  }
}
.info-block__grid {
  margin: 5rem -0.9375rem 0 -0.9375rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media only screen and (max-width: 720px) {
  .info-block__grid {
    margin: 2.5rem 0 0 0;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 3.75rem;
  }
}
.info-block__column {
  padding: 0.9375rem;
  width: calc(100% / 3);
}
@media only screen and (max-width: 720px) {
  .info-block__column {
    padding: 0;
    width: 100%;
  }
}
.info-block__image {
  position: relative;
  padding: 1.75rem;
  height: 22.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.info-block__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.info-block__subtitle {
  font-size: 3.25rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .info-block__subtitle {
    font-size: 3rem;
  }
}
.info-block__subtitle span {
  color: #e6792d;
}
.info-block__text {
  margin-top: 2rem;
  font-size: 1.25rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #fff;
  line-height: 1.4;
}
@media only screen and (max-width: 720px) {
  .info-block__text {
    margin-top: 1.75rem;
    font-size: 1rem;
  }
}
.info-block__text p:not(:last-child) {
  margin: 0 0 2.25rem 0;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .info-block__text p:not(:last-child) {
    margin: 0 0 1.75rem 0;
  }
}
.info-block__text ul li {
  padding-left: 1rem;
  margin-left: 0.625rem;
}
.info-block__text ul li::marker {
  content: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10V0L6 4.99624L0 10Z' fill='%23E6792D'/%3E%3C/svg%3E");
}
.info-block__text ul li:not(:last-child) {
  margin-bottom: 2.25rem;
}
.contacts {
  margin-top: 3.75rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-column-gap: 7.8125rem;
  -moz-column-gap: 7.8125rem;
  column-gap: 7.8125rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .contacts {
    margin-top: 2.625rem;
    -webkit-column-gap: 4.375rem;
    -moz-column-gap: 4.375rem;
    column-gap: 4.375rem;
  }
}
@media only screen and (max-width: 720px) {
  .contacts {
    margin-top: 1.5rem;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 1.5rem;
  }
}
.contacts-info {
  width: calc(265 / 1140 * 100%);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .contacts-info {
    width: calc(270 / 957 * 100%);
    font-size: 1rem;
  }
}
@media only screen and (max-width: 720px) {
  .contacts-info {
    width: 100%;
  }
}
.contacts-info__line {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.contacts-info__line:not(:last-child) {
  margin-bottom: 1.75rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .contacts-info__line:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}
.contacts-info__line.orange {
  color: #e6792d;
}
.contacts-info__icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.9375rem;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.contacts-info__icon svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}
.contacts-map {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  height: 25.625rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .contacts-map {
    height: 21.5625rem;
  }
}
@media only screen and (max-width: 720px) {
  .contacts-map {
    height: 25rem;
  }
}
.contacts-map #map {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 720px) {
  .about-top {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.about-top__image {
  margin: 0 0 2.625rem 2.0625rem;
  width: 22.5rem;
  height: 22.5rem;
  float: right;
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -o-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-top__image {
    margin: 0 0 1.3125rem 1.3125rem;
    width: 18.9375rem;
    height: 18.9375rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-top__image {
    margin: 1.75rem 0 0;
    padding-bottom: clamp(21.3125rem, 70vw, 30.625rem);
    width: 100%;
    height: 0;
    position: relative;
  }
  .about-top__image img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.about-title {
  font-size: 4rem;
  font-weight: bold;
  color: #758791;
  -webkit-box-ordinal-group: 0;
  -moz-box-ordinal-group: 0;
  -o-box-ordinal-group: 0;
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
  text-transform: uppercase;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-title {
    font-size: 2.25rem;
  }
}
.about-text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -o-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}
.about-text p {
  margin: 0;
}
.about-text p:not(:last-child) {
  margin: 0 0 1.75rem;
}
.about-text__top {
  margin-top: 3.125rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-text__top {
    margin-top: 2.5rem;
    font-size: 1rem;
  }
}
.about-text__bottom {
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -o-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}
@media only screen and (max-width: 720px) {
  .about-text {
    margin-top: 1.75rem;
  }
}
.about-bottom {
  margin-top: 3rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-bottom {
    margin-top: 2.25rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-bottom {
    margin-top: 1.75rem;
  }
}
.about-bottom__title {
  margin-bottom: 3.75rem;
  font-size: 1.25rem;
  color: #758791;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-bottom__title {
    margin-bottom: 3.25rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-bottom__title {
    margin-bottom: 2.5rem;
  }
}
.about-facts {
  margin: -0.9375rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-facts {
    margin: -0.625rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-facts {
    margin: -1.25rem;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.about-fact {
  padding: 0.9375rem;
  width: calc(100% / 3);
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-fact {
    padding: 0.625rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-fact {
    padding: 1.25rem;
    width: 100%;
  }
}
.about-fact__slogan {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 3.75rem;
  white-space: nowrap;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-fact__slogan {
    margin-bottom: 2.5rem;
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .about-fact__slogan {
    margin-bottom: 1.75rem;
    width: fit-content;
  }
}
.about-fact__slogan-left {
  color: #758791;
}
.about-fact__slogan-right {
  margin-top: -0.75rem;
  margin-left: 30%;
}
.about-fact__slogan-right span {
  color: #e6792d;
}
.about-fact__text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .about-fact__text {
    font-size: 1rem;
  }
}
.about-fact__text p {
  margin: 0;
}
.about-fact__text p:not(:last-child) {
  margin: 0 0 1.75rem 0;
}
.about-fact__button {
  margin-top: 2.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 1.25rem;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.about-fact__button a {
  display: block;
  padding: 0.75rem 0.9375rem;
  text-transform: uppercase;
  font-size: 1.25rem;
  width: fit-content;
  color: #000;
  -webkit-transition: filter 0.3s ease;
  -moz-transition: filter 0.3s ease;
  -o-transition: filter 0.3s ease;
  -ms-transition: filter 0.3s ease;
  transition: filter 0.3s ease;
}
.about-fact__button a:hover {
  filter: brightness(0.75);
}
.about-fact__button a.orange {
  background-color: #e6792d;
}
.about-fact__button a.grey {
  background-color: #828d93;
}
.portfolio-title {
  font-size: 4rem;
  color: #758791;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .portfolio-title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 720px) {
  .portfolio-title {
    font-size: 2.25rem;
  }
}
.portfolio-row {
  margin-top: 3.75rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .portfolio-row {
    margin-top: 3.125rem;
  }
}
@media only screen and (max-width: 720px) {
  .portfolio-row {
    margin-top: 2.5rem;
  }
}
.portfolio-row__title {
  padding-right: 1.625rem;
  font-size: 4rem;
  text-transform: uppercase;
  width: fit-content;
}
.portfolio-row__title::first-letter {
  color: #e6792d;
}
@media only screen and (max-width: 1025px) {
  .portfolio-row__title {
    font-size: 1.875rem;
  }
}
.portfolio-decorline {
  position: relative;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.portfolio-decorline::before {
  content: '';
  position: absolute;
  top: 0.5rem;
  left: 0;
  right: 0;
  height: 0.3125rem;
  background-color: #90a4af;
}
@media only screen and (max-width: 720px) {
  .portfolio-decorline::before {
    height: 0.1875rem;
  }
}
.portfolio-blocks {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 1.875rem;
  margin-top: 2.25rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .portfolio-blocks {
    row-gap: 1rem;
  }
}
@media only screen and (max-width: 720px) {
  .portfolio-blocks {
    row-gap: initial;
  }
}
.portfolio-blocks__row {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  margin: -0.9375rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .portfolio-blocks__row {
    margin: -0.5rem;
  }
}
@media only screen and (max-width: 720px) {
  .portfolio-blocks__row {
    margin: 0;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .portfolio-blocks__row:first-child {
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.portfolio-block {
  position: relative;
  padding: 0.9375rem;
  width: calc((100% / 3));
  height: 22.5rem;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .portfolio-block {
    padding: 0.5rem;
    height: 18.75rem;
  }
}
@media only screen and (max-width: 720px) {
  .portfolio-block {
    padding: 0;
    width: 100% !important;
    height: auto;
  }
}
.portfolio-block--big {
  width: calc(100% / 3 * 2);
}
@media only screen and (max-width: 720px) {
  .portfolio-slider .swiper-slide .portfolio-block--big {
    display: none;
  }
}
.portfolio-slider {
  width: 100%;
}
.portfolio-pagination {
  position: absolute;
  width: calc(100% / 3);
  height: 100%;
}
@media only screen and (max-width: 720px) {
  .portfolio-pagination {
    width: 100%;
    height: 32.9375rem;
  }
}
.portfolio-pagination .swiper-pagination {
  --swiper-pagination-bottom: 0.75rem;
}
.portfolio-pagination.rightd {
  right: 0;
}
.portfolio-inner {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
  padding: 0.75rem;
  height: 100%;
}
@media only screen and (max-width: 720px) {
  .portfolio-inner {
    text-align: center;
    padding: 0;
  }
}
.portfolio-inner__logo {
  padding: 0.9375rem 0 1.875rem;
  height: 7.8125rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
@media only screen and (min-width: 720px) and (max-width: 1025px) {
  .portfolio-inner__logo {
    height: 6.5625rem;
  }
}
.portfolio-inner__logo img {
  object-fit: contain;
  width: initial;
  height: initial;
  max-width: 100%;
  max-height: 100%;
}
.portfolio-inner__banner {
  height: 22.125rem;
}
@media only screen and (min-width: 720px) {
  .portfolio-inner__banner {
    display: none;
  }
}
.portfolio-inner__title {
  margin-top: 1.0625rem;
  color: #758791;
  font-weight: bold;
}
@media only screen and (max-width: 720px) {
  .portfolio-inner__title {
    margin-top: 3rem;
  }
}
.portfolio-inner__description {
  margin-top: 1.1875rem;
  max-width: 17.4375rem;
  font-weight: 300;
}
@media only screen and (max-width: 720px) {
  .portfolio-inner__description {
    padding: 0 1.25rem;
    max-width: none;
  }
}
@media only screen and (max-width: 720px) {
  .portfolio-slider__background {
    margin: 0 -0.9375rem;
    padding: 0 0.9375rem 1.875rem;
  }
  .portfolio-slider__background:nth-child(odd) {
    background-color: #1a282e;
  }
}
.not-found {
  max-width: 38.125rem;
}
.not-found__wrapper {
  padding-top: 5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 720px) {
  .not-found__wrapper {
    padding-top: 2.5rem;
  }
}
.not-found__numbers {
  margin: 0 auto;
  font-size: 18.75rem;
  font-weight: bold;
  color: #e6792d;
  position: relative;
  width: fit-content;
}
@media only screen and (max-width: 720px) {
  .not-found__numbers {
    font-size: 12.5rem;
  }
}
.not-found__label {
  position: absolute;
  top: 16%;
  left: -24%;
  background: #253238;
  padding: 0.25rem;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #fff;
}
@media only screen and (max-width: 720px) {
  .not-found__label {
    font-size: 1.625rem;
    font-weight: normal;
  }
}
.not-found__description {
  font-size: 2.5rem;
  color: #758791;
}
@media only screen and (max-width: 720px) {
  .not-found__description {
    font-size: 1.5rem;
  }
}
.not-found__description span {
  color: #e6792d;
}
.not-found__bottom {
  margin-top: 5.375rem;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.2;
}
@media only screen and (max-width: 720px) {
  .not-found__bottom {
    margin-top: 3.125rem;
    font-size: 1rem;
  }
}
.not-found__bottom a {
  color: #e6792d;
}
