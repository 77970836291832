.clients
	&-container
		padding: rem(80) 0 rem(74);
		position: relative;
		+tablet()
			padding: rem(60) 0 rem(52);
		+mobile()
			padding: rem(40) 0 rem(52);
		&::before
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: rem(5);
			background-color: #90A4AF;
			+mobile()
				height: rem(3);
	&-row
		display: flex;
	&-title
		padding-right: rem(26);
		font-size: rem(64);
		text-transform: uppercase;
		width: fit-content;
		+tablet()
			font-size: rem(48);
		+mobile()
			font-size: rem(36);
	&-decorline
		position: relative;
		flex-grow: 1;
		&::before
			content: '';
			position: absolute;
			top: rem(8);
			left: 0;
			right: 0;
			height: rem(5);
			background-color: #90A4AF;
			+mobile()
				height: rem(3);
	&-slider .swiper-slide
		width: auto;
		height: rem(200);
		+tablet()
			height: rem(168);
	&-slider
		padding: rem(80) 0 rem(105);
		a
			display: block;
			width: 100%;
			height: 100%;
		.swiper-pagination
			position: relative;
