.promotion
	&-blocks
		display: flex;
		flex-direction: column;
		row-gap: rem(30);
		+tablet()
			row-gap: rem(16);
		+mobile()
			row-gap: rem(10)
	
	&-row
		display: flex;
		margin: rem(-15);
		+tablet()
			margin: rem(-8);
		+mobile()
			margin: rem(-5)
			flex-direction: column;
			&:first-child
				flex-direction: column-reverse;
	
	&-block
		padding: rem(15);
		width: calc((100% / 3));
		+tablet()
			padding: rem(8);
		+mobile()
			padding: rem(5);
			width: 100% !important;
		
		&--big
			width: calc(100% / 3 * 2);
	
	&-additional
		position: absolute;
		left: rem(39);
		bottom rem(-46)
		text-transform: uppercase;
		transition bottom 0.6s ease
		+tablet()
			bottom: rem(48);
		+mobile()
			left: rem(24);
			bottom: rem(50);
		
		&.rotated
			left: initial;
			width fit-content
			transform translate(100%, 0) rotate(0deg)
			transition transform 0.6s ease
			+below($tablet)
				transform: translate(rem(90), rem(-20)) rotate(-45deg);
			+below($mobile)
				transform: translate(rem(200%), rem(-30%)) rotate(-45deg);
	
	&-title
		font-size: rem(42);
		font-weight: bold;
		margin-bottom: rem(30);
	
	&-button
		margin: 0 auto;
		padding: rem(12) rem(15);
		background: $orange;
		font-size: rem(22);
		width: fit-content;
		color: black;
		transition 0.3s ease background-color
		
		&:hover
			background-color $yellow
.promotion-block__inner
	display block
	position: relative;
	overflow hidden
	width 100%
	height: rem(360);
	+tablet()
		height: rem(300);
	+mobile()
		height: calc(344 / 376 * 100%);
	+desktop()
		&:hover
			.promotion-additional:not(.rotated)
				bottom: rem(48);
			
			.promotion-additional.rotated
				transform: translate(rem(150), rem(-150)) rotate(-45deg);
			
			img.elements
				transform translate(rem(20), rem(20)) rotate(20deg)
				
				&.left
					transform translate(-10%, -10%) rotate(160deg)
			
			img.bg
				opacity 0.5
			
			.triangle img
				transform rotate(180deg)
			
			.triangle-label
				transform translate(rem(-20), 50%)
	
	img.bg
		transition opacity 0.6s ease
	
	img.elements
		position absolute
		object-fit contain
		width auto
		height auto
		transition transform 0.6s ease
		
		&.left
			transform rotate(180deg)
			left rem(-60)
			top rem(-15)
		
		&.right
			right rem(-60)
			bottom rem(-15)
	
	.triangle
		position absolute
		right 0
		bottom rem(30)
		width rem(87)
		height rem(170)
		overflow hidden
		+below($tablet)
			font-size rem(18)
			bottom 50%
			transform translateY(70%)
		
		img
			transition transform 0.6s ease
			+below($tablet)
				transform rotate(180deg)
	
	.triangle-label
		position absolute
		right 0
		bottom 50%
		transform translate(110%, 50%)
		transition transform 0.6s ease
		+below($tablet)
			transform translate(rem(-20), 50%)
