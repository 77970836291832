.header {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 10;
	background-color: $primary;
	
	.header-top {
		padding: rem(13) 0;
		
		&__list {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		
		&__item {
			font-size: rem(16);
		}
	}
	
	.header-bottom {
		padding: rem(10) 0;
		background: $secondary;
		transition height 0.3s ease
		
		&__row {
			display: flex;
			align-items: center;
		}
		
		&__block.center {
			+below($tablet) {
				margin: 0 auto;
			}
		}
		
		&.active {
			#header-organizers {
				span {
					color $orange
				}
			}
			
			.header-nav__submenu {
				pointer-events initial
				transform: translateY(0)
				opacity 1
			}
		}
	}
	
	&-logo {
		display: block;
		width: rem(175);
		height: rem(45);
	}
	
	&-nav {
		margin: 0 auto;
		padding: 0 rem(40);
		display: flex;
		align-items: center;
		column-gap: rem(70);
		
		+below($tablet) {
			display: none;
		}
		
		&__item {
			position relative
			cursor pointer
			font-size: rem(30);
			
			span {
				transition: 0.3s color ease;
			}
			
			+above($tablet) {
				&:hover span {
					color: $orange;
				}
			}
			
			&.active {
				color: $orange;
			}
		}
		
		&__submenu {
			padding-top rem(28)
			position absolute
			opacity 0
			pointer-events none
			transition opacity 0.2s ease
		}
		
		&__subitem {
			white-space: nowrap
			svg {
				margin-right rem(10)
				width rem(13)
				height rem(13)
			}
		}
	}
	
	&-contacts {
		margin-right: rem(50);
		text-align: right;
		
		+below($tablet) {
			margin: 0;
		}
		
		&__phone {
			margin-bottom: rem(4);
			font-size: rem(18);
			
			span {
				color: $grey;
			}
			
			+below($mobile) {
				margin: 0;
			}
		}
		
		&__mail {
			color: $orange;
			font-size: rem(14);
			
			+below($mobile) {
				display: none;
			}
		}
	}
	
	&-langpicker {
		position relative
		background: $orange;
		font-size: rem(20);
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(25);
		width: rem(25);
		text-transform uppercase
		
		&:hover {
			.header-langpicker__values {
				max-height rem(125)
			}
		}
		
		+below($tablet) {
			display: none;
		}
		
		> span {
			padding rem(2)
			display: block;
			color: black;
		}
		
		&__values {
			position: absolute;
			top rem(26)
			left 0
			right 0
			display flex
			flex-direction column
			row-gap rem(1)
			overflow hidden
			transition max-height 0.3s ease
			max-height 0
			cursor: pointer;
			
			li {
				padding rem(2)
				text-align center
				background-color $grey
				width 100%
				a {
					display block
				}
			}
		}
	}
}

.mobile {
	padding: rem(80) rem(35);
	position: fixed;
	background: $primary;
	width: 50%;
	right: 0;
	z-index: 10;
	transition: transform 0.3s ease;
	transform: translateX(0);
	height: 100%;
	
	&:not(.open) {
		transform: translateX(100%);
	}
	
	+above($tablet) {
		display: none;
	}
	
	&-menu {
		display: flex;
		flex-direction: column;
		row-gap: rem(70);
		
		&__submenu {
			padding-top rem(16)
			padding-left rem(10)
			display flex
			flex-direction column
			row-gap rem(20)
			font-size rem(20)
		}
		&__subitem {
			//display flex
			white-space nowrap
			
			svg {
				margin-right rem(10)
				width rem(13)
				height rem(13)
			}
		}
	}
	
	&-item {
		font-size: rem(36);
		color: #fff;
		
		&:first-letter {
			color: $orange;
		}
	}
	
	&-background {
		position: absolute;
		bottom: 0;
		height: rem(350);
		left: 0;
	}
}
