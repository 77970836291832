.portfolio
	&-title
		font-size: rem(64);
		color: $greyText;
		+tablet()
			font-size rem(48)
		+mobile()
			font-size rem(36)
	&-row
		margin-top: rem(60);
		display: flex;
		+tablet()
			margin-top rem(50)
		+mobile()
			margin-top: rem(40)
		&__title
			padding-right: rem(26);
			font-size: rem(64);
			text-transform: uppercase;
			width: fit-content;
			&::first-letter
				color: $orange;
			+below($tablet)
				font-size: rem(30);
	&-decorline
		position: relative;
		flex-grow: 1;
		&::before
			content: '';
			position: absolute;
			top: rem(8);
			left: 0;
			right: 0;
			height: rem(5);
			background-color: #90A4AF;
			+mobile()
				height: rem(3);
	&-blocks
		display: flex;
		flex-direction: column;
		row-gap: rem(30);
		margin-top rem(36)
		+tablet()
			row-gap: rem(16);
		+mobile()
			row-gap: initial;
		&__row
			display: flex;
			margin: rem(-15);
			+tablet()
				margin: rem(-8);
			+mobile()
				margin: 0;
				flex-direction: column;
				&:first-child
					flex-direction: column-reverse;
	&-block
		position: relative;
		padding: rem(15);
		width: calc((100% / 3));
		height: rem(360);
		+tablet()
			padding: rem(8);
			height: rem(300);
		+mobile()
			padding: 0;
			width: 100% !important;
			height auto
		&--big
			width: calc(100% / 3 * 2);

.portfolio-slider .swiper-slide .portfolio-block--big
	+mobile()
		display none

.portfolio-slider
	width 100%

.portfolio-pagination
	position absolute
	width calc(100% / 3)
	height 100%
	+mobile()
		width 100%
		height rem(125) + rem(354) + rem(48)
	.swiper-pagination
		--swiper-pagination-bottom rem(12)
	&.rightd
		right 0


.portfolio-inner
	font-family: 'Roboto Condensed', sans-serif;
	font-size: rem(16);
	padding: rem(12)
	height 100%
	+mobile()
		text-align center
		padding 0
	&__logo
		padding rem(15) 0 rem(30)
		height: rem(125);
		display: flex;
		align-items: center;
		justify-content: center;
		+tablet()
			height rem(105)
		img
			object-fit: contain;
			width: initial;
			height: initial;
			max-width 100%
			max-height 100%
	&__banner
		height rem(354)
		+above($mobile)
			display none
	&__title
		margin-top: rem(17);
		color: $greyText;
		font-weight: bold;
		+mobile()
			margin-top rem(48)
	&__description
		margin-top: rem(19);
		max-width: rem(279);
		font-weight: 300;
		+mobile()
			padding 0 rem(20)
			max-width none

.portfolio-slider__background
	+mobile()
		margin 0 rem(-15)
		padding 0 rem(15) rem(30)
		&:nth-child(odd)
			background-color $secondary
