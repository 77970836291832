.burger-button
	+above($tablet)
		display none
	width: rem(28);
	height: rem(28);
	position: relative;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	span
		display: block;
		position: absolute;
		height: rem(2)
		width: 100%;
		background: #fff;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

.burger-button span:nth-child(1) {
	top: 0;
	transform-origin: left center;
}

.burger-button span:nth-child(2) {
	top: rem(13);
	transform-origin: left center;
}

.burger-button span:nth-child(3) {
	top: rem(26)
	transform-origin: left center;
}

.burger-button.open span:nth-child(1) {
	transform: rotate(45deg);
	top: rem(3);
	left: rem(4);
}

.burger-button.open span:nth-child(2) {
	width: 0;
	opacity: 0;
}

.burger-button.open span:nth-child(3) {
	transform: rotate(-45deg);
	top: rem(23)
	left: rem(4)
}
