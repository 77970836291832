.about
	&-top
		+mobile()
			display flex
			flex-direction column
		&__image
			margin 0 0 rem(42) rem(33)
			width rem(360)
			height rem(360)
			float right
			order 2
			+tablet()
				margin 0 0 rem(21) rem(21)
				width rem(303)
				height rem(303)
			+mobile()
				margin rem(28) 0 0
				padding-bottom clamp(rem(341), 70vw, rem(490))
				width 100%
				height 0
				position relative
				img
					position absolute
					top 0
					right 0
					bottom 0
					left 0
	&-title
		font-size rem(64)
		font-weight bold
		color $greyText
		order 0
		text-transform uppercase
		+tablet()
			font-size rem(48)
		+mobile()
			font-size rem(36)
	&-text
		font-family "Roboto Condensed", sans-serif
		font-size rem(20)
		font-weight 300
		order 1
		p
			margin 0
		p:not(:last-child)
			margin 0 0 rem(28)
		&__top
			margin-top rem(50)
			+tablet()
				margin-top rem(40)
				font-size rem(16)
		&__bottom
			order 3
		+mobile()
			margin-top rem(28)

.about
	&-bottom
		margin-top rem(48)
		+tablet()
			margin-top rem(36)
		+mobile()
			margin-top rem(28)
		&__title
			margin-bottom rem(60)
			font-size rem(20)
			color $greyText
			+tablet()
				margin-bottom rem(52)
			+mobile()
				margin-bottom rem(40)
	&-facts
		margin rem(-15)
		display flex
		+tablet()
			margin rem(-10)
		+mobile()
			margin rem(-20)
			flex-direction column
	&-fact
		padding rem(15)
		width calc(100%/3)
		+tablet()
			padding rem(10)
		+mobile()
			padding rem(20)
			width 100%
		&__slogan
			font-size rem(48)
			text-transform uppercase
			font-weight bold
			margin-bottom rem(60)
			white-space nowrap
			+tablet()
				margin-bottom rem(40)
				font-size rem(40)
			+mobile()
				margin-bottom rem(28)
				width fit-content
			&-left
				color $greyText
			&-right
				margin-top rem(-12)
				margin-left 30%
				span
					color $orange
		&__text
			font-family "Roboto Condensed", sans-serif
			font-size rem(20)
			font-weight 300
			+tablet()
				font-size rem(16)
			p
				margin 0
			p:not(:last-child)
				margin 0 0 rem(28) 0
		&__button
			margin-top rem(40)
			display flex
			flex-direction column
			row-gap rem(20)
			align-items center
			a
				display block
				padding rem(12) rem(15)
				text-transform uppercase
				font-size rem(20)
				width fit-content
				color #000
				transition filter 0.3s ease
				&:hover
					filter brightness(0.75)
				&.orange
					background-color $orange
				&.grey
					background-color $grey
