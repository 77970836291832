.footer
	padding rem(32) 0 rem(20)
	position relative
	background $secondary
	+below(720px)
		padding rem(34) 0 rem(15)
		text-align center
	&-background
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		pointer-events none
		display flex
		justify-content space-between
		svg
			height 100%
			width rem(492)
		+below($tablet)
			display none
	&-logo
		margin-bottom rem(18)
		width rem(175)
		height rem(45)
	&-row
		margin-bottom rem(88)
		display flex
		justify-content space-between
		+below(720px)
			flex-direction column
			align-items center
			gap rem(50)
	&-left
		font-family 'Roboto Condensed', sans-serif
		font-size rem(20)
		font-weight 300
		+tablet()
			font-size rem(16)
	&-address
		margin-bottom rem(24)
	&-phone
		margin-bottom rem(9)
	&-mail
		color $orange
	&-nav
		display flex
		gap rem(70)
		+below(720px)
			gap rem(40)
			flex-direction column
		&__item
			font-size rem(30)
			transition color 0.3s ease
			color $greyText
			+above($tablet)
				&:hover
					color $orange
			+tablet()
				font-size rem(24)
	&-copyright
		color $greyText
		text-align center
