@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');
@import 'nib';
@import 'rupture';

rupture.mobile-cutoff = 720px;
rupture.desktop-cutoff = 1025px;

@import 'variables.styl';
@import 'global.styl';
// region components
@import './components/header.styl';
@import './components/footer.styl';
@import './components/slider.styl';
@import './components/promotion.styl';
@import './components/clients.styl';
@import './components/burger.styl';
@import './components/info_block.styl';
@import './components/contacts.styl';
@import './components/about.styl';
@import './components/portfolio.styl';
@import './components/404.styl';
