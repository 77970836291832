.not-found
	max-width rem(610)
	&__wrapper
		padding-top rem(80)
		display flex
		flex-direction column
		align-items center
		text-align center
		+mobile()
			padding-top rem(40)
	&__numbers
		margin 0 auto
		font-size rem(300)
		font-weight bold
		color $orange
		position relative
		width fit-content
		+mobile()
			font-size rem(200)
	&__label
		position absolute
		top 16%
		left -24%
		background $primary
		padding rem(4)
		font-size rem(40)
		text-transform uppercase
		color #ffffff
		+mobile()
			font-size rem(26)
			font-weight normal
	&__description
		font-size rem(40)
		color $greyText
		+mobile()
			font-size rem(24)
		span
			color $orange
	&__bottom
		margin-top rem(86)
		font-family "Roboto Condensed", sans-serif
		font-size rem(20)
		font-weight 300
		line-height 1.2
		+mobile()
			margin-top rem(50)
			font-size rem(16)
		a
			color $orange
