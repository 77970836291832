.info-block
	&__title
		font-size rem(64)
		font-weight bold
		color $greyText
		text-transform uppercase
		+tablet()
			font-size rem(48)
		+mobile()
			font-size rem(36)
	&__grid
		margin rem(80) rem(-15) 0 rem(-15)
		display flex
		+mobile()
			margin rem(40) 0 0 0
			flex-direction column
			row-gap rem(60)
	&__column
		padding rem(15)
		width calc(100% / 3)
		+mobile()
			padding 0
			width 100%
	&__image
		position relative
		padding rem(28)
		height rem(360)
		display flex
		align-items flex-end
		img
			position absolute
			top 0
			right 0
			bottom 0
			left 0
			z-index -1
	&__subtitle
		font-size rem(52)
		font-weight bold
		color #fff
		text-transform uppercase
		+tablet()
			font-size rem(48);
		span
			color $orange
	&__text
		margin-top rem(32)
		font-size rem(20)
		font-family 'Roboto Condensed', sans-serif
		font-weight 300
		color #fff
		line-height 1.4
		+mobile()
			margin-top rem(28)
			font-size rem(16)
		p:not(:last-child)
			margin 0 0 rem(36) 0
			+tablet()
				margin 0 0 rem(28) 0
		ul li
			padding-left rem(16)
			margin-left rem(10)
			&::marker
				content url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 10V0L6 4.99624L0 10Z' fill='%23E6792D'/%3E%3C/svg%3E");
			&:not(:last-child)
				margin-bottom rem(36)
				+mobile()
					rem(28)
