.contacts
	margin-top rem(60)
	display flex
	justify-content space-between
	column-gap rem(125)
	+tablet()
		margin-top rem(42)
		column-gap rem(70)
	+mobile()
		margin-top rem(24)
		flex-direction column
		row-gap rem(24)
	&-info
		width calc(265 / 1140 * 100%)
		font-family 'Roboto Condensed', sans-serif
		font-size rem(20)
		font-weight 300
		+tablet()
			width calc(270 / 957 * 100%)
			font-size rem(16)
		+mobile()
			width 100%
		&__line
			display flex
			&:not(:last-child)
				margin-bottom rem(28)
				+tablet()
					margin-bottom rem(20)
			&.orange
				color $orange
		&__icon
			width rem(20)
			height rem(20)
			margin-right rem(15)
			flex-shrink 0
			svg
				width 100%
				height 100%
				fill #fff
	&-map
		flex-grow 1
		height rem(410)
		border-radius rem(4)
		overflow hidden
		+tablet()
			height rem(345)
		+mobile()
			height rem(400)
		#map
			width 100%
			height 100%

