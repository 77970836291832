em(size, defaultSize = 14)
	return (size / defaultSize) em;

rem(size)
	return (size / 16) rem;

@font-face
	font-family: 'Rodchenko';
	// src: url('../fonts/Rodchenko.ttf');
	src: url('/static/fonts/Rodchenko.ttf');

html
	font-family: 'Rodchenko', sans-serif;
	font-weight: 400;
	line-height: 1;
	color: #fff;
	font-size: 16px;
	+between($tablet, 1200px)
		font-size: rem(15);
	+between($mobile, $tablet)
		font-size: calc((100vw / 64));
	+below(450px)
		font-size: calc((100vw / 28.125));

body
	margin: 0;
	position: relative;
	background: $primary;

main
	padding-top: rem(107);
	position: relative;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button
input::-webkit-inner-spin-button
	-webkit-appearance: none;
	margin: 0;

/* Firefox */
input[type=number]
	-moz-appearance: textfield;

ul
li
	margin: 0;
	padding: 0;
	list-style: none;

a
	text-decoration: none;
	color: inherit;

*:focus
	outline: none;

*
*::before
*::after
	box-sizing: border-box;

.container
	margin: 0 auto;
	padding: 0 rem(40);
	max-width: rem(1220);
	+tablet()
		padding: 0 rem(30);
	+mobile()
		padding: 0 rem(15);

.content
	padding: rem(30) 0 rem(85);
	position: relative;
	+tablet()
		padding: rem(24) 0 rem(48);
	&.overflow-hidden
		overflow hidden

.background-layout
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: rem(390);
	z-index: -1;
	display flex
	justify-content space-between
	svg
		height 100%
		width rem(560)
	+below($tablet)
		display none
	&.fullheight
		display block
		height 100%
		img
			transform scale(2.5)

img
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;

.swiper
	height: 100%;
	&-slide
		cursor: grab;
	&-wrapper
		align-items: center;
	&-pagination-bullet
		--swiper-pagination-bullet-horizontal-gap: rem(9);
		--swiper-pagination-bullet-width: rem(12);
		--swiper-pagination-bullet-height: rem(12);
		--swiper-pagination-bullet-inactive-color: #90A4AF;
		--swiper-pagination-color: $orange;

.mainpage-link
	margin-bottom: rem(20);
	display: flex;
	align-items: center;
	color: $greyText;
	+tablet()
		display: none;
	svg
		margin-left: rem(3);
